<template>
  <div class="order-tracking-detail" style="margin-top: 2%">
    <div class="tracking-info">
      <div class="infoTitle">
        <i class="leftLine"></i>
        {{ $t('售后信息') }}
      </div>
      <a-spin :spinning="tableLoading">
        <div class="info-body">
          <div class="col-md-12 no-padding" v-if="tempList.length > 0">
            <div class="order-goods-table">
              <a-table
                :pagination="false"
                :columns="columns"
                :rowKey="(record, index) => index"
                :dataSource="tempList"
                :scroll="{ x: 1000 }"
                style="margin-top: 1%"
              >
                <!-- 售后单号-->
                <template slot="AfterSaleOrderNumber" slot-scope="text, record">
                  <a @click="goToAfterSale(record)">
                    {{ record.aftersale_order_sn }}
                  </a>
                </template>

                <!-- 售后类型 -->
                <template slot="AfterSalesType" slot-scope="text, record">
                  {{ record.aftersale_type | AfterSalesTypeFilter(aftersaleTypeList) }}
                </template>

                <!-- 售后状态 -->
                <template slot="AfterSalesStatus" slot-scope="text, record">
                  {{ record.aftersale_status | AfterSalesStatusFilter(aftersaleStatusList) }}
                </template>

                <!-- 买家ID/收货号码-->
                <template slot="BuyerId" slot-scope="text, record">
                  {{ record.buyer_id }} / {{ record.receiver_mobile }}
                </template>

                <!-- 备注-->
                <template slot="note" slot-scope="text, record">
                  {{ record.remark }}
                </template>
              </a-table>
            </div>
          </div>
          <div v-else>
            <a-row>
              <a-col :span="11">{{ $t('暂无售后') }}</a-col>
            </a-row>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'

export default {
  props: {
    propsOrderDetail: {
      default: {
        originOrderSns: [],
        originOrders: [],
      },
    },
    aftersaleTypeList: [],
    aftersaleStatusList: [],
  },
  data() {
    return {
      tempList: [],
      tableLoading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t(`售后单号`),
          scopedSlots: { customRender: 'AfterSaleOrderNumber' },
          key: 'aftersale_order_sn',
          width: 140,
        },
        {
          title: this.$t(`原始售后单号`),
          dataIndex: 'platform_aftersale_sn',
          width: 140,
        },
        {
          title: this.$t(`售后类型`),
          scopedSlots: { customRender: 'AfterSalesType' },
          key: 'aftersale_type',
          width: 100,
        },
        {
          title: this.$t(`售后状态`),
          scopedSlots: { customRender: 'AfterSalesStatus' },
          key: 'WAIT_SELLER_AGREE',
          width: 100,
        },
        {
          title: this.$t(`买家ID/收货号码`),
          scopedSlots: { customRender: 'BuyerId' },
          key: 'BuyerId',
          width: 100,
        },
        {
          title: this.$t(`创建时间`),
          dataIndex: 'created_at',
          width: 150,
        },
        {
          title: this.$t(`备注`),
          scopedSlots: { customRender: 'note' },
          key: 'remark',
          width: 100,
        },
      ]
    },
  },
  created() {
    this.getAftersaleOrderInfo()
  },
  methods: {
    getAftersaleOrderInfo() {
      this.tableLoading = true
      http({
        instance: this,
        url: api.aftersaleOrderInfo,
        type: 'post',
        data: {
          shop_code: this.propsOrderDetail.shop.shopNick,
          platform: this.propsOrderDetail.shop.shopPlatform,
          platform_order_sn: this.propsOrderDetail.platformOrderId,
        },
        hasLoading: false,
        success: (res) => {
          this.tableLoading = false
          this.tempList = res.result.aftersale_order_list
        },
      })
    },
    goToAfterSale(record) {
      // this.$router.push(`/aftersale_web/aftersale/detail/${record.aftersale_order_id}`)
      this.$addPane({
        view: `aftersale_web/aftersale/detail/${record.aftersale_order_id}`,
        name: '售后详情',
        params: {
          orderId: record.aftersale_order_id,
          orderSn: record.aftersale_order_sn,
        },
        route: `/aftersale_web/aftersale/detail/${record.aftersale_order_id}`,
      })
    },
  },
  filters: {
    AfterSalesTypeFilter(type, list) {
      let result = ''
      list.forEach((item) => {
        if (item.name == type) {
          result = item.desc
        }
      })
      return result
    },
    AfterSalesStatusFilter(type, list) {
      let result = ''
      list.forEach((item) => {
        if (item.name == type) {
          result = item.desc
        }
      })
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.order-tracking-detail {
  float: left;
  width: 100%;
  font-size: 12px;
}

.order-tracking-detail .tracking-info {
  margin-bottom: 0px;
}

.order-tracking-detail .info-body {
  padding: 10px 0px 10px 0px;
  background-color: $white;
  width: 100%;
  color: #666;
  float: left;
}

.not-editable {
  border: 0px;
  background-color: $white;
}

.steps-content {
  padding: 40px 30px;
}
</style>
