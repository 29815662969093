<template>
  <div>
    <component :is="'overlay'" v-show="showOverlay" :on-click="cancelNoteAdd"></component>
    <div>
      <a-modal
        :title="$t(`添加备注内容`)"
        :visible="visible"
        @ok="handlerClick"
        @cancel="cancelNoteAdd"
      >
        <p>
          <a-textarea :cols="cols" :rows="rows" v-model="note" />
          <a-row style="margin-top: 2%">
            <a-col :span="4">{{ $t(`使用颜色`) }}</a-col>
            <a-col :span="3">
              <span
                class="color-box"
                style="background-color: blue"
                @click="color = 'blue'"
                :class="{ selected: color == 'blue' }"
              ></span>
            </a-col>
            <a-col :span="3">
              <span
                class="color-box"
                style="background-color: red"
                @click="color = 'red'"
                :class="{ selected: color == 'red' }"
              ></span>
            </a-col>
            <a-col :span="3">
              <span
                class="color-box"
                style="background-color: grey"
                @click="color = 'grey'"
                :class="{ selected: color == 'grey' }"
              ></span>
            </a-col>
          </a-row>
        </p>
      </a-modal>

      <!-- <div class="form-group">
        <label class="col-md-12 control-label">{{
          $t(`添加备注内容`)
        }}</label>
      </div>
      <div class="form-group">
        <textarea
          class="form-control"
          :cols="cols"
          :rows="rows"
          v-model="note"
        ></textarea>
      </div>
      <div class="form-group">
        <label class="col-md-2 control-label">{{ $t(`使用颜色`) }}</label>
        <div class="col-md-6 control-label" style="text-align: left"></div>
      </div> -->

      <!-- <div class="col-md-12 form-horizontal action-buttons">
        <div class="col-md-3"></div>
        <div class="col-md-3 btns">
          <div class="btn btn-sm btn-info" @click="cancelNoteAdd">
            {{ $t(`取消`) }}
          </div>
        </div>
        <div class="col-md-3 btns">
          <div class="btn btn-sm btn-primary" @click="handlerClick">
            {{ $t(`确认`) }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import overlay from '../component/overlay'

export default {
  data() {
    return {
      note: '',
      color: '',
      visible: true,
    }
  },
  props: {
    showOverlay: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '400px',
    },
    cols: {
      type: Number,
      default: 30,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    style() {
      return {
        width: this.width,
      }
    },
  },
  methods: {
    cancelNoteAdd() {
      this.$emit('cancel', false)
    },
    handlerClick() {
      if (this.note == '') {
        this.$message.warning(this.$t(`备注内容不能为空`))
      } else {
        this.$emit('confirm', {
          note: this.note,
          color: this.color,
        })
      }
    },
  },
  components: { overlay },
}
</script>

<style scoped lang="scss">
.note-add-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $white;
  margin: 60px auto;
  float: none;
  opacity: 1;
  padding: 20px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90%;
  z-index: 1002;
  margin-top: 10%;
  border-radius: 2px;
}
.note-add-modal label {
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
}
.btn-cancel {
  border-color: #367fa9;
  color: #367fa9;
}
.color-box {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 1px;
  cursor: pointer;
}
.selected {
  border: 2px solid #ccc;
}
.btns {
  text-align: center;
}
.logBtn {
  display: inline-block;
  padding: 3px 15px;
  cursor: pointer;
  margin: 10px 0;
}
.trueBtn {
  color: #0066ff;
  border: 1px solid #0066ff;
  margin-left: 30px;
}
.falseBtn {
  color: #ff0000;
  border: 1px solid #ff0000;
}
.btn {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 30px;
}
</style>
