<template>
  <iframe
    name="fd-jspdf"
    id="fd-jspdf"
    :src="`/static/pdfjs/web/viewer.html?file=${encodeURIComponent(pdfUrl)}`"
    :style="styles"
    frameborder="0"
    class="pdf-window"
  ></iframe>
</template>

<script>
export default {
  props: ['pdfUrl'],
  computed: {
    styles() {
      return {
        width: '100%',
        height: window.innerHeight * 0.8 + 'px',
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
